<template>
    <section class="wizard-background">
     <div class="container">
         <div class="generic-wizard-heading">
             Select Your Board
         </div>
         <p class="generic-wizard-subheading">
             Click on the tabs below to select your respective board.
             <br/>
Don’t worry you are just a few clicks away.


         </p>
     </div>
    </section>
</template>

<script>
export default {
    

}
</script>

